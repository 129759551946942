.Login-container{

    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
    background-image: linear-gradient(to bottom right, #000046, #1CB5E0);

    .Login-content{

        display: flex;
        flex-direction: column;
        justify-content: center;

        margin-top:5vh;
        margin-left:25vw;
        width: 50%;
        min-height: 85%;
        padding: 45px;
    
        background-color: white;
        box-shadow: 10px 10px 51px -2px rgba(0,0,0,0.75);
        border-radius:20px;
        color: black;
        text-align:center;



        @media screen and (max-width: 765px) {
            width: 90%;
            padding:35px;
            height:100%;
            margin:20px;
        }

        .Login-top{
            h2{
                font-weight: 700;
            }
        }

        .Login-bottom{

            text-align: left;
            margin-top: 5vh;

            .element{
                margin-top: 20px;

                .input{
                    font-size:1.3rem;
                }
                p {
                    font-size: 1rem;
                }
            }

            #Login-button{
                font-weight: 700;
                margin-top:10px;
            }
        }

        .Login-footer{
            display: flex;
            flex-direction: row;
            margin-top: 5vh;
            font-size:20px;

            #Signup-button{

                margin-left: 15px;
            }
        }
        #logo{
           width: 100px;

        }

    }
}