.Summary-container{

    height: 85vh;
    width: 20vw;
    box-shadow: 10px 10px 51px -2px rgba(0,0,0,0.75);
    border-radius:20px;
    padding:10px;
    background-color: white;

    .Summary-content{

        
        h3{
            font-weight: 700;
        }
        .StudentList-list{
        }
    }
}