.ConfirmEmail-container{

    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    background-image: linear-gradient(to bottom right, #000046, #1CB5E0);

    .ConfirmEmail-content{

        display: flex;
        flex-direction: column;
        justify-content: center;

        margin-top:25vh;
        margin-left:25vw;
        width: 50%;
        height: 50%;
        padding: 45px;
    
        background-color: white;
        box-shadow: 10px 10px 51px -2px rgba(0,0,0,0.75);
        border-radius:20px;
        color: black;
        text-align:center;

        #logo {
            width:100px;
        }
        .ConfirmEmail-top{
            h2{
                font-weight: 700;
            }
        }
    }
}