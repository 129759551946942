.onedocument2-modal{
    height: 30vh;
    width:30vw;

    @media screen and (max-width: 765px) {
        height: 40vh;
        width: 80vw;
    }
}

.DocumentStatusLine-container{


    .DocumentStatusLine-content{

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 70%;
        align-items: center;
        margin-bottom: 10px;

        #icon{

            margin: 0 20px;
            font-size: 20px;
        }
        
    }
}