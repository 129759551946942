.rs-picker-menu.rs-picker-select-menu{
    z-index: 200000;
}
.documents-modal{

    height:75vh;
    overflow-y: scroll;
    width: 80vw;
}

.input-field{
    margin-bottom: 15px;
}
.StudentList-container{

    height: 80vh;
    width: 60vw;
    box-shadow: 10px 10px 51px -2px rgba(0,0,0,0.75);
    border-radius:20px;

    background-color: white;

    overflow-y: scroll;

    
    .StudentList-content{

        text-align: left;
        padding: 30px;


        


        .header{
            h3{
                font-weight: 700;
            }

            .filter{
                display: flex;
                flex-direction: row;
                width: 70%;
                justify-content: space-between;

                .toggle{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                }

                p{
                    margin-right: 10px;
                }
            }
        }

        .StudentList-list{
            width: 170vw;
            margin-top: 20px;

            tr th{

                font-size: 1rem;
                text-align: left;
            }

            tr{
                font-size:1rem;
                text-align: left;
            }

        }
    }
}