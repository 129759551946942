.MainAdmin-container{

    margin: auto;
    margin-top: 5vh;
    width: 90vw;
    height: 90vh;
    padding: 40px;

   // background-color: white;
    //box-shadow: 10px 10px 51px -2px rgba(0,0,0,0.75);
    border-radius:20px;
    color: black;

    .MainAdmin-content{

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .Block-content{

            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
}

