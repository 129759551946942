.OneStudent-container{

    width: 100vw;
    margin-bottom: 10px;
    line-height: 2rem;

    .document-button{

        font-size: 20px;
    }


    .OneStudent-content{

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

       
        p{
            margin-top: 0px;
            margin-left:20px;
            font-size: 17px;
        }
    }
}