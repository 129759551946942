.OneDocument-container{

    margin-bottom: 5vh;

    .OneDocument-content{

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        button{

            font-size: 10px;
        }
    }
}