.NewsElement-container{

    border-bottom: 1px solid rgb(206, 206, 206);
    padding-bottom: 10px;
    margin: 20px 0px;
    .NewsElement-content{

        #date {

            font-weight: 700;
            font-size: 15px;
            margin-top:0px;
        }
        #title {

            font-weight: 700;
            font-size: 25px;

        }
        #content{

            font-size:20px;
        }
    }
}