.Signup-container{

    margin: auto;
    margin-top: 5vh;
    width: 75vw;
    max-height: 90vh;
    padding: 40px;
    overflow-y: scroll;

    background-color: white;
    box-shadow: 10px 10px 51px -2px rgba(0,0,0,0.75);
    border-radius:20px;
    color: black;

    @media screen and (max-width: 765px) {
        width: 90%;
        height: 100vh;
        margin:20px;

        h1 {
            font-size:25px;
            font-weight: 800;
            line-height: 20px;
        }
    }
    .Signup-content{

        
        text-align: left;

        h2{
            font-weight: 800;
        }

        #startText{

            margin-top: 10vh;
        }

        .Input-element{

            margin: 20px 0;

            .input {
                font-size: 1.3rem;
            }
            #input {
                font-size: 1.3rem;
            }

            #declar{
                font-size:15px;
            }
            
            p{

                font-size:20px;
            }
        }
    }
}