.DashboardAdmin-container{

    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    padding-left: 3vw;
    overflow-y: hidden;


    .DashboardAdmin-content{
        display: flex;
        flex-direction : row;


        .Dashboard-navbar{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            max-width: 20vw;
            height: 100vh;

            #logo{
                margin-top: 20px;
                margin-bottom: 25vh;
                width: 50px;

            }
        }


        .Dashboard-container{

            background-image: linear-gradient(to bottom right, #000046, #1CB5E0);
            width: 100%;
        }
    }
}