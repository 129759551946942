.Documents-container{

    margin: auto;
    margin-top: 5vh;
    width: 75vw;
    height: 90vh;
    padding: 40px;
    overflow-y: scroll;

    background-color: white;
    box-shadow: 10px 10px 51px -2px rgba(0,0,0,0.75);
    border-radius:20px;
    color: black;

    @media screen and (max-width: 765px) {
        width: 80vw;

        h1 {
            font-weight: 800;
            font-size: 30px;
            line-height: 30px;
        }
    }

    .Documents-content{

        text-align: left;
        
        .Upload-zone{
            margin-bottom: 50px;


            h3{
                font-weight: 800;
                font-size: 20px;
                @media screen and (max-width: 765px) {
                    font-size: 20px;
                    line-height: 30px;
                }
            }
        }
    }
}

.onedocument-modal{

    height:80vh;
    width:80vw;

    .crop-container{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 80px;
        margin-top:200px;
    }
}

