.Initial_Context-container{

    margin-top:25vh;
    margin-left:25vw;
    width: 50%;
    height: 60%;
    padding: 40px;

    background-color: white;
    box-shadow: 10px 10px 51px -2px rgba(0,0,0,0.75);
    border-radius:20px;
    color: black;

    @media screen and (max-width: 765px) {
        width: 90%;
        height: 100vh;
        margin:20px;
    }

    .Initial_Context-content{
        text-align: left;

        h2{
            font-weight: 800;
        }

        #startText{

            margin-top: 10vh;
        }
    }
}