.SignupLogin-container{


    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    background-image: linear-gradient(to bottom right, #000046, #1CB5E0);

    .SignupLogin-content{

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        margin-top:5vh;
        margin-left:15vw;
        width: 70%;
        max-height: 90vh;
        padding: 45px;
        overflow-y: scroll;
    
        background-color: white;
        box-shadow: 10px 10px 51px -2px rgba(0,0,0,0.75);
        border-radius:20px;
        color: black;
        text-align: left;

        @media screen and (max-width: 765px) {
            width: 90%;
            height:100%;
            margin:20px;
        }

        

        .footer{

            font-size: 1rem;

            .submit-button{

                font-size: 1.3rem;
            }
        }
        .top-info{

            h3{

                font-size: 2rem;
                font-weight: 700;
            }
        }

        .Input-element{
            margin-bottom: 3vh;

            .input {
                font-size:1.3rem;
            }

            #input {
                font-size:1.3rem;
            }

            p{
                font-size: 1.2rem;
            }
        }

    }
}