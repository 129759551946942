.ParticipantInfo-container{

    height: 35vh;
    width: 35vw;
    box-shadow: 10px 10px 51px -2px rgba(0,0,0,0.75);
    border-radius:20px;

    background-color: white;

    @media screen and (max-width: 765px) {
        height: 35vh;
        width: 80vw;
    }

    .ParticipantInfo-content{

        padding: 20px;
        text-align: left;


        h3{
            font-size: 20px;
            font-weight: 700;
        }
        p{
            font-size:17px;
        }

    }
}